import type { DataGridControl } from 'o365-datagrid';
import type { Ref } from 'vue';

let ctMessenger: undefined | (() => void) = undefined;

/**
 * Begin listening for messages 
 */
async function init(pCallbacks: DetailFunctions) {
    const { GlobalMessenger } = await import('o365-modules');
    if (ctMessenger) { ctMessenger(); }
    return ctMessenger = GlobalMessenger.on((pMessage: any) => {
        if (isDetailMessage(pMessage)) {
            if (pMessage.action == 'load' && pMessage.itemId && pCallbacks?.load) {
                const loadPromise = pCallbacks.load(pMessage.itemId);
                if (window.parent && loadPromise && loadPromise instanceof Promise) {
                    // Validate the loaded item id with the current selected item in master app
                    loadPromise.then(loadedId => {
                        const payload = GlobalMessenger.payloadToMessage({
                            type: 'detail-sync',
                            itemId: loadedId,
                            action: 'validate',
                        });
                        window.parent.postMessage(payload);
                    });
                }
            }
        }
    });
}

/**
 * Starts a message listener that will listen for scope item validation messages. When the given 
 * item id differs from currenlty selected on in grid, will send a new load request to the detail iframe.
 */
async function initDetailValidation(pGridRef: Ref<DataGridControl>) {
    const { GlobalMessenger, appInsights } = await import('o365-modules');
    const { logger } = await import('o365-utils');
    if (ctMessenger) { ctMessenger(); }
    let prevLoadedId: number | null = null;
    return ctMessenger = GlobalMessenger.on((pMessage: any) => {
        if (isDetailMessage(pMessage)) {
            if (pGridRef.value == null) { return; }
            if (pMessage.action == 'validate' && pMessage.itemId) {
                const dataGridControl = pGridRef.value.dataGridControl;
                const itemId = pMessage.itemId;
                const dataObject = dataGridControl.dataObject;
                if (dataObject.current?.ID != itemId && prevLoadedId != itemId) {
                    logger.warn(`Detail loaded with id: ${itemId}, but currently selected row is ${dataObject.current.ID}. Reloading`);
                    // Grid and detail are out of sync, send new load request to the detail.
                    const iframe = dataGridControl.menuTabs.iframe
                    const payload = GlobalMessenger.payloadToMessage({
                        type: 'detail-sync',
                        action: 'load',
                        itemId: dataObject.current.ID,
                    });
                    iframe.contentWindow.postMessage(payload);

                    // Track event in appinsights for further investigation
                    appInsights.trackEvent('detail-sync: validation failed', {
                        loadedId: pMessage.itemId,
                        currentId: dataObject.current?.ID,
                    });
                }
                prevLoadedId = itemId;
            }
        }
    });
}

function isDetailMessage(pObj: any): pObj is DetailSyncMessage {
    return pObj && typeof pObj == 'object' && pObj.type == 'detail-sync';
}

type DetailFunctions = {
    load: (pItemId: number) => Promise<number> | void
}

type DetailSyncMessage = {
    type: 'detail-sync',
    action: 'load' | 'validate',
    itemId: number,
}

export { init, initDetailValidation };